import formattedTime from './formattedTime';
import formattedDate from './formattedDate';
import { isToday, isTomorrow, isYesterday } from 'date-fns';

const formattedTimeAndDate = (dateObj, options = {}) => {
  const { showDate = true, timeZone } = options;

  const time = formattedTime(dateObj, { timeZone });

  const formatter = dateObj => {
    if (isTomorrow(dateObj)) {
      return `tomorrow`;
    } else if (isToday(dateObj)) {
      return `today`;
    } else if (isYesterday(dateObj)) {
      return `yesterday`;
    } else {
      return `on ${formattedDate(dateObj)}`;
    }
  };

  const date = formatter(dateObj);

  return [time, showDate ? date : null].filter(Boolean).join(' ');
};

export default formattedTimeAndDate;
